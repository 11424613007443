<template>
  <footer class="bg-blue-500 text-gray-700 body-font">
    <div class="container px-3 py-4 mx-auto justify-center flex items-center flex-row">
      <a
        href="https://career.indomaretgroup.com"
        target="_blank"
        class="flex title-font font-bold text-sm items-center justify-center text-white"
      >Indomaret Group</a>
      <p class="text-xs text-white ml-2 pl-2 border-l-2 border-gray-200">© 2021 All rights reserved</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>